import { ComponentProps, FC } from "react";
import { Icon, Link } from "@gnist/design-system";
import { styled } from "styled-components";
import { tokens } from "@gnist/themes/tokens.css.js";

type Props = ComponentProps<typeof Link>;

const StyledLink = styled(Link)`
  display: inline-flex;
  text-decoration: none;
  color: ${tokens.color["on-inverse-background"]};
  transition: 0.3s ease-in-out transform;

  .arrow {
    margin-left: ${tokens.spacing.base};
  }

  &:hover .arrow {
    transform: translateX(3px);
  }
`;

export const ArrowLink: FC<Props> = ({ children, ...rest }) => {
  return (
    <StyledLink {...rest}>
      <span>{children}</span>
      <Icon className="arrow" icon="arrow_forward" />
    </StyledLink>
  );
};
