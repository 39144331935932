"use client";

import { styled } from "styled-components";
import NextLink from "../nextLink";
import { MMGLogo } from "../icons";
import {
  breakpointDesktop,
  breakpointMobile,
  Heading1Text,
} from "@gnist/design-system";
import { UnstyledList } from "../styled-common/unstyledList";
import { Link } from "@gnist/design-system";
import { ArrowLink } from "./arrowLink";
import { footerPaddingInline } from "@/styles/mixins";
import { tokens } from "@gnist/themes/tokens.css.js";

const FooterWrapper = styled.footer`
  padding-block: ${tokens.spacing["3xl"]} 72px;
  ${footerPaddingInline()}
  background-color: ${tokens.color["inverse-background"]};
  color: ${tokens.color["on-inverse-background"]};

  > div {
    max-width: 1728px;
    margin-inline: auto;
  }
`;

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: ${tokens.spacing["3xl"]} ${tokens.spacing.m};

  padding-bottom: ${tokens.spacing.xl};
  border-bottom: 1px solid ${tokens.color.outline};
  margin-bottom: ${tokens.spacing.xl};

  @media screen and (min-width: ${breakpointMobile}) {
    flex-direction: row;

    .bruktbil {
      width: 100%;

      > * {
        max-width: 352px;
      }
    }

    .contact {
      text-align: right;
    }
  }

  @media screen and (min-width: ${breakpointDesktop}) {
    flex-wrap: nowrap;

    .bruktbil {
      width: auto;
    }
  }
`;

const Heading = styled(Heading1Text)`
  margin-bottom: ${tokens.spacing.s};
  font-size: ${tokens.typeface.size.base};
  line-height: 150%;
`;

const List = styled(UnstyledList)`
  li {
    margin-bottom: ${tokens.spacing.xxs};
  }
`;

export const Footer = () => {
  return (
    <FooterWrapper>
      <InformationWrapper>
        <div className="bruktbil">
          <Heading>Bruktbil</Heading>
          <p>
            Vi selger bruktbiler fra ulike forhandlere over hele Norge. Finn din
            neste bruktbil fra tusenvis av bruktbiler til salgs.
          </p>
          <br />
          <p>Bruktbil er et produkt fra Møller Mobility Group.</p>
        </div>
        <div>
          <Heading>Nyttig informasjon</Heading>
          <List>
            <li>
              <ArrowLink $as={NextLink} href="/innbytte-av-bil">
                Innbytte av bil
              </ArrowLink>
            </li>
            <li>
              <ArrowLink $as={NextLink} href="/personvern">
                Personvern
              </ArrowLink>
            </li>
            <li>
              <ArrowLink $as={NextLink} href="/informasjonskapsler">
                Informasjonskapsler
              </ArrowLink>
            </li>
          </List>
        </div>
        <div className="contact">
          <Heading>Kontakt oss</Heading>
          <List>
            <li>
              <p>Org.nr 943 733 988</p>
            </li>
            <li>
              <Link href="mailto:kundeservice@moller.no">
                kundeservice@moller.no
              </Link>
            </li>
          </List>
        </div>
      </InformationWrapper>
      <div>
        <MMGLogo />
      </div>
    </FooterWrapper>
  );
};
