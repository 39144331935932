"use client";

import {
  BodyText,
  breakpointDesktop,
  IconButton,
  Icon,
} from "@gnist/design-system";
import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { Menu } from "./menu";
import { tokens } from "@gnist/themes/tokens.css.js";

const MobileMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow: auto;
  top: 55px;
  right: 0;
  left: 0;
  z-index: 100;
  background-color: ${tokens.color.background};
  padding: ${tokens.spacing.xl} ${tokens.spacing.l};
  height: calc(
    100vh -
      55px - calc(${tokens.spacing.xl} * 2) - env(safe-area-inset-top) - env(
        safe-area-inset-bottom
      )
  );
  height: -webkit-fill-available;
  row-gap: ${tokens.spacing.xl};

  > a {
    color: ${tokens.color["on-background"]};
    font-size: ${tokens.typeface.size.l};
    text-decoration: none;
  }

  @media screen and (min-width: ${breakpointDesktop}) {
    display: none;
  }
`;

const ToggleMenuButton = styled(IconButton)`
  margin-left: auto;

  @media screen and (min-width: ${breakpointDesktop}) {
    display: none !important;
  }
`;

const MenuButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: ${tokens.color["on-background"]};
  > div {
    font-size: ${tokens.typeface.size.l};
  }
`;

const Chevron = styled(Icon)`
  margin-left: ${tokens.spacing.xxs};
`;

export const MobileMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isDepartmentsOpen, setDepartmentsOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  return (
    <>
      <ToggleMenuButton
        icon={isMenuOpen ? "close" : "menu"}
        label="Meny"
        onClick={() => setMenuOpen(!isMenuOpen)}
      />
      {isMenuOpen && (
        <MobileMenuWrapper>
          <BodyText
            style={{ fontWeight: tokens.typeface.weight.semibold }}
            $as="a"
            href="/"
          >
            Alle biler
          </BodyText>
          <MenuButton onClick={() => setDepartmentsOpen(!isDepartmentsOpen)}>
            <BodyText style={{ fontWeight: tokens.typeface.weight.semibold }}>
              Velg forhandler
            </BodyText>
            <Chevron icon={isDepartmentsOpen ? "expand_less" : "expand_more"} />
          </MenuButton>
          {isDepartmentsOpen && (
            <Menu
              closeMenu={() => {
                setMenuOpen(false);
                setDepartmentsOpen(false);
              }}
            />
          )}
        </MobileMenuWrapper>
      )}
    </>
  );
};
