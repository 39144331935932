import { AlertBanner } from "@gnist/design-system";
import { styled } from "styled-components";
import { useFeatureToggle } from "../../feature-toggle/featureToggleProvider";
import { tokens } from "@gnist/themes/tokens.css.js";

export const Alert = styled(AlertBanner)`
  margin-bottom: ${tokens.spacing.xl};
`;

export const AlertMessage = () => {
  const { alertMessage } = useFeatureToggle();

  if (alertMessage && alertMessage.message)
    return (
      <Alert
        message={alertMessage.message}
        heading={alertMessage.heading || ""}
        type={alertMessage.type || "info"}
      />
    );
  else return <></>;
};
