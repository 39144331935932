import { css } from "styled-components";
import { breakpointDesktop, breakpointTablet } from "@gnist/design-system";
import { tokens } from "@gnist/themes/tokens.css.js";

// Todo: can we access theme directly?
export const globalPaddingInline = () => css`
  padding-inline: ${tokens.spacing.l};

  @media screen and (min-width: 1200px) {
    padding-inline: 176px;
  }
`;

export const footerPaddingInline = () => css`
  padding: ${tokens.spacing.xl};

  @media screen and (min-width: ${breakpointTablet}) {
    padding-inline: 128px;
  }

  @media screen and (min-width: ${breakpointDesktop}) {
    padding-inline: 224px;
  }
`;
