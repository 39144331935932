"use client";

import { styled } from "styled-components";
import { globalPaddingInline } from "../../styles/mixins";
import { MenuSection } from "./menuSection";
import { breakpointDesktop } from "@gnist/design-system";
import {
  DepartmentMenuItem,
  getMenuDealers,
} from "./data/getDepartmentsMenuData";
import { sortAlphabetically } from "../../utils/sortAlphabetically";
import {
  departmentGroups,
  standaloneDepartments,
} from "@/data/forhandler/departments.config";
import { tokens } from "@gnist/themes/tokens.css.js";

const MenuWrapper = styled.nav`
  padding-bottom: calc(${tokens.spacing.xl} * 2);

  @media screen and (min-width: ${breakpointDesktop}) {
    position: absolute;
    top: 60px;
    right: 0;
    left: 0;
    z-index: 100;
    display: flex;
    column-gap: ${tokens.spacing["3xl"]};
    padding-block: ${tokens.spacing.l} ${tokens.spacing["3xl"]};
    ${globalPaddingInline()}
    background-color: ${tokens.color.background};
    box-shadow: ${tokens.elevation.low};
  }
`;

interface Props {
  closeMenu: () => void;
}

export const Menu = ({ closeMenu }: Props) => {
  const departments = getMenuDealers(departmentGroups, standaloneDepartments);
  const sortedDepartments = sortAlphabetically<DepartmentMenuItem[]>(
    departments,
    "name",
  );

  return (
    <MenuWrapper>
      <MenuSection
        departments={sortedDepartments}
        startLetter="A"
        endLetter="E"
        closeMenu={closeMenu}
      />
      <MenuSection
        departments={sortedDepartments}
        startLetter="F"
        endLetter="J"
        closeMenu={closeMenu}
      />
      <MenuSection
        departments={sortedDepartments}
        startLetter="K"
        endLetter="O"
        closeMenu={closeMenu}
      />
      <MenuSection
        departments={sortedDepartments}
        startLetter="P"
        endLetter="T"
        closeMenu={closeMenu}
      />
      <MenuSection
        departments={sortedDepartments}
        startLetter="U"
        endLetter="Å"
        closeMenu={closeMenu}
      />
    </MenuWrapper>
  );
};
