"use client";

import { LogoBrand } from "@gnist/design-system/foundation/logos/Logo.js";
import { bilholdLight } from "@gnist/themes/themes/bilholdLight.css.js";
import { gumpen } from "@gnist/themes/themes/gumpen.css.js";
import { mollerBil } from "@gnist/themes/themes/mollerBil.css.js";
import { skodaDark } from "@gnist/themes/themes/skodaDark.css.js";
import { vw } from "@gnist/themes/themes/vw.css.js";
import { dahles } from "@gnist/themes/themes/dahles.css.js";
import { autoria } from "@gnist/themes/themes/autoria.css.js";
import { audi } from "@gnist/themes/themes/audi.css.js";
import { cupra } from "@gnist/themes/themes/cupra.css.js";
import { globalTextStyles } from "@gnist/themes/typography.css.js";
import { tokens } from "@gnist/themes/tokens.css.js";
import React, {
  createContext,
  ReactNode,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { createGlobalStyle, styled } from "styled-components";
import "@gnist/design-system/fonts/moller";
import "@gnist/design-system/fonts/bilhold";
import "@gnist/design-system/fonts/skoda";
import "@gnist/design-system/fonts/gumpen";
import "@gnist/design-system/fonts/VW";
import "@gnist/design-system/fonts/dahles";
import "@gnist/design-system/fonts/autoria";
import "@gnist/design-system/fonts/audi";
import "@gnist/design-system/fonts/cupra";

export const getThemeFromDealerName = (
  dealerName: string | null,
): LogoBrand => {
  if (!dealerName) return "bilhold";
  const x = dealerName.toLowerCase().replace("ø", "o");
  if (x.includes("moller")) return "mollerbil";
  if (x.includes("gumpen")) return "gumpen";
  if (x.includes("autoria")) return "autoria";
  if (x.includes("dahles")) return "dahles";
  return "bilhold";
};

const getCssThemeClass = (theme: string) => {
  switch (theme) {
    case "mollerbil":
      return mollerBil;
    case "gumpen":
      return gumpen;
    case "skoda":
      return skodaDark;
    case "vw":
    case "nytte":
      return vw;
    case "dahles":
      return dahles;
    case "autoria":
      return autoria;
    case "audi":
      return audi;
    case "cupra":
      return cupra;
    case "bilhold":
    default:
      return bilholdLight;
  }
};

const isDarkTheme = (theme: LogoBrand) => theme === "skoda";

const ThemeContext = createContext<
  | {
      theme: LogoBrand;
      isDarkTheme: boolean;
      setTheme: (theme: LogoBrand | null) => void;
    }
  | undefined
>(undefined);

export const useThemeContext = () => {
  const ctx = useContext(ThemeContext);

  if (!ctx) {
    throw new Error(
      "useThemeContext must be used within a ThemeContextProvider",
    );
  }

  return ctx;
};

export const ThemeContextProvider: React.FC<{
  defaultTheme: LogoBrand;
  children: ReactNode;
}> = ({ defaultTheme, children }) => {
  const setTheme = (theme: LogoBrand | null) => {
    setState({
      ...state,
      theme: theme || defaultTheme,
      isDarkTheme: isDarkTheme(theme || defaultTheme),
    });
  };

  const initialTheme = defaultTheme;

  const initState = {
    theme: initialTheme,
    isDarkTheme: isDarkTheme(initialTheme),
    setTheme: setTheme,
  };

  const [state, setState] = useState(initState);
  const selectedTheme = state.theme;

  useLayoutEffect(() => {
    document.body.classList.add(getCssThemeClass(selectedTheme));
    document.body.classList.add(...globalTextStyles);
    return () => {
      document.body.classList.remove(getCssThemeClass(selectedTheme));
    };
  }, [selectedTheme]);

  return (
    <ThemeContext.Provider value={state}>
      <div className={getCssThemeClass(selectedTheme)}>{children}</div>
    </ThemeContext.Provider>
  );
};

// Theme flickering fix: We override the global styles to decrease the time it takes to switch themes
const OverrideGlobalTextStyles = createGlobalStyle`
    html, body {
        background-color: ${tokens.color.background} !important;
        color: ${tokens.color["on-background"]} !important;
    }
`;

// Theme flickering fix: We wrap the children in a div to override the background color
const ChildThemeWrapper = styled.div`
  background-color: ${tokens.color.background};
  color: ${tokens.color["on-background"]};
`;

export const ChildThemeProvider: React.FC<{
  theme: LogoBrand | null;
  children: ReactNode;
}> = ({ theme, children }) => {
  const parentThemeState = useThemeContext();
  const { setTheme } = parentThemeState;

  useLayoutEffect(() => {
    if (theme !== null) {
      setTheme(theme);
    }
    return () => {
      setTheme(null);
    };
  }, [theme, setTheme]);

  const selectedTheme = theme || parentThemeState.theme;

  const state = {
    theme: selectedTheme,
    isDarkTheme: isDarkTheme(selectedTheme),
    setTheme: parentThemeState.setTheme,
  };

  return (
    <ThemeContext.Provider value={state}>
      <ChildThemeWrapper className={getCssThemeClass(selectedTheme)}>
        <OverrideGlobalTextStyles />
        {children}
      </ChildThemeWrapper>
    </ThemeContext.Provider>
  );
};
