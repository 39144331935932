"use client";

import { styled } from "styled-components";
import NextLink from "../nextLink";
import {
  IconButton,
  BodyText,
  breakpointDesktop,
  Heading2Text,
} from "@gnist/design-system";
import { globalPaddingInline } from "../../styles/mixins";
import { Menu } from "./menu";
import { useState } from "react";
import { useOutsideClick } from "../../utils/hooks/useOutsideClick";
import { MobileMenu } from "./mobileMenu";
import { AlertMessage } from "../alertMessage/alertMessage";
import { tokens } from "@gnist/themes/tokens.css.js";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  gap: ${tokens.spacing.s};
  min-height: calc(55px - calc(${tokens.spacing.xs} * 2) - 1px);
  padding-block: ${tokens.spacing.xs};
  ${globalPaddingInline()}
  background-color: ${tokens.color.background};

  @media screen and (min-width: ${breakpointDesktop}) {
    min-height: calc(60px - calc(${tokens.spacing.xs} * 2) - 1px);
    border-bottom: 0.5px solid ${tokens.color.outline};
  }
`;

const Logo = styled.div`
  ${Heading2Text.selector} {
    font-size: ${tokens.typeface.size.l};
    font-weight: ${tokens.typeface.weight.semibold};
    color: ${tokens.color["on-background"]};
    text-decoration: none;

    &:visited {
      color: ${tokens.color["on-background"]};
    }
  }
`;

const Items = styled.div`
  display: none;
  align-items: center;
  padding: 0 ${tokens.spacing["3xl"]};
  column-gap: ${tokens.spacing.xl};
  a {
    color: ${tokens.color["on-background"]};
    text-decoration: none;
  }

  @media screen and (min-width: ${breakpointDesktop}) {
    display: flex;
  }
`;

export const Navigation = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const ref = useOutsideClick(() => setMenuOpen(false));

  return (
    <>
      <Nav>
        <Logo>
          <Heading2Text $as={NextLink} href="/">
            Bruktbil
          </Heading2Text>
        </Logo>
        <Items>
          <BodyText
            style={{ fontWeight: tokens.typeface.weight.semibold }}
            $as="a"
            href="/"
          >
            Alle biler
          </BodyText>
          <div ref={ref}>
            <IconButton
              icon={isMenuOpen ? "expand_less" : "expand_more"}
              label="Velg forhandler"
              onClick={() => setMenuOpen(!isMenuOpen)}
              showLabel="left"
            />
            {isMenuOpen && <Menu closeMenu={() => setMenuOpen(false)} />}
          </div>
        </Items>
        <MobileMenu />
      </Nav>
      <AlertMessage />
    </>
  );
};
