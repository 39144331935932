"use client";

import { styled } from "styled-components";
import { breakpointDesktop, Link } from "@gnist/design-system";
import NextLink from "../nextLink";
import { tokens } from "@gnist/themes/tokens.css.js";

const MenuSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacing.xs};
  min-width: 190px;
  &:not(:first-of-type) {
    margin-top: ${tokens.spacing.l};
  }

  @media screen and (min-width: ${breakpointDesktop}) {
    &:not(:first-of-type) {
      margin-top: 0;
    }
  }
`;

const SectionTitle = styled.p`
  font-size: 10px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  line-height: ${tokens.typeface["line-height"]};
  font-weight: 500;
  color: ${tokens.color["on-background"]};
`;

const Divider = styled.hr`
  display: flex;
  border: none;
  height: 0.5px;
  background-color: ${tokens.color.outline};
  width: calc(100vw - calc(${tokens.spacing.l}* 2));
  margin: ${tokens.spacing.base} 0 0;

  @media screen and (min-width: ${breakpointDesktop}) {
    display: none;
  }
`;

interface MenuSectionProps {
  departments: { name: string; slug: string }[];
  startLetter: string;
  endLetter: string;
  closeMenu: () => void;
}

export const MenuSection = ({
  departments,
  startLetter,
  endLetter,
  closeMenu,
}: MenuSectionProps) => {
  const filteredDepartments = departments.filter(
    (department) =>
      department.name.charAt(0).toUpperCase() >= startLetter &&
      department.name.charAt(0).toUpperCase() <= endLetter,
  );

  return (
    <MenuSectionWrapper>
      <div>
        <SectionTitle>{`${startLetter}-${endLetter}`}</SectionTitle>
        <Divider />
      </div>
      {filteredDepartments.map((department) => (
        <NavLink
          key={department.slug}
          $as={NextLink}
          href={`/forhandler/${department.slug}`}
          onClick={() => closeMenu()}
        >
          {department.name}
        </NavLink>
      ))}
    </MenuSectionWrapper>
  );
};
