"use client";

import { globalPaddingInline } from "@/styles/mixins";
import { Link, Logo, breakpointDesktop } from "@gnist/design-system";
import { styled } from "styled-components";
import { tokens } from "@gnist/themes/tokens.css.js";

const Nav = styled.nav`
  display: flex;
  align-items: center;
  min-height: calc(55px - calc(${tokens.spacing.xs} * 2) - 1px);
  padding-block: ${tokens.spacing.xs};
  ${globalPaddingInline()}
  background-color: ${tokens.color.background};

  @media screen and (min-width: ${breakpointDesktop}) {
    min-height: calc(60px - calc(${tokens.spacing.xs} * 2) - 1px);
    border-bottom: 0.5px solid ${tokens.color.outline};
  }
`;

export const MollerBilHeader = () => {
  return (
    <header>
      <Nav>
        <Link href="https://mollerbil.no" target="_blank">
          <Logo size="xxl" type="wordmark" brand="mollerbil" color="#0000ff" />
        </Link>
      </Nav>
    </header>
  );
};
