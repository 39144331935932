"use client";

import { usePathname } from "next/navigation";
import { FC } from "react";
import { Link } from "@gnist/design-system";
import { styled } from "styled-components";
import { disablePreview } from "./previewUrls";
import { tokens } from "@gnist/themes/tokens.css.js";

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10px;
  width: 300px;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-inline: auto;
  padding: 20px;

  background-color: ${tokens.color.warning};
  color: ${tokens.color["on-warning"]};
`;

export const PreviewToggler: FC<{ inPreview: boolean }> = ({ inPreview }) => {
  const pathName = usePathname();

  if (!inPreview) {
    return null;
  }

  return (
    <Wrapper>
      <Link href={disablePreview(pathName)}>Lukk preview</Link>
    </Wrapper>
  );
};
